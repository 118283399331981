import profilepicture from './profilepicture.png';
import { withTranslation } from 'react-i18next';
import './cv.scss';
import '../../page.scss';

const CV = ({ t }) => {
    return (
        <div id="cv" className="page">
            <div id="main" className="columns">
                <div className="small-column">
                    <header>
                        <h1><span>Bas</span> <span>Franke</span></h1>
                        <em>Software engineer</em>
                        <div className="center">
                            <img src={profilepicture} alt="profile" />
                        </div>
                    </header>
                    <section id="contact">
                        <h2>{t('contact')}</h2>
                        <div>
                            <ul>
                                <li>
                                    <span>Almere, Europalaan 938</span>
                                    <span><i className="fas fa-map-marker-alt"></i></span>
                                </li>
                                <li>
                                    <span>06 208 388 09</span>
                                    <span><i className="fas fa-phone-alt"></i></span>
                                </li>
                                <li>
                                    <span>bas.franke.89@gmail.com</span>
                                    <span><i className="fas fa-envelope"></i></span>
                                </li>
                                <li>
                                    <span><a href="https://bas-franke.com" target="_blank">www.bas-franke.com</a></span>
                                    <span><i className="fas fa-link"></i></span>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section>
                        <h2>{t('profile')}</h2>
                        <div>
                            <p>{t('profile-text')}</p>
                        </div>
                    </section>
                    <section>
                        <h2>{t('languages')}</h2>
                        <div>
                            <ul className="languages">
                                <li>{t('languages-dutch')}</li>
                                <li>{t('languages-english')}</li>
                            </ul>
                        </div>
                    </section>
                    <section id="skills">
                        <h2>{t('skills')}</h2>
                        <div>
                            <ul>
                                <li>
                                    <div className="progressbar">
                                        <div>{t('skill-coding')}</div>
                                        <div className="inner-progressbar" style={{ width: "90%" }}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="progressbar">
                                        <div>{t('skill-scrum')}</div>
                                        <div className="inner-progressbar" style={{ width: "100%" }}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="progressbar">
                                        <div>{t('skill-teamwork')}</div>
                                        <div className="inner-progressbar" style={{ width: "85%" }}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="progressbar">
                                        <div>{t('skill-leadership')}</div>
                                        <div className="inner-progressbar" style={{ width: "94%" }}></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>{t("knowledge")}</h2>
                            <ul id="knowledge">
                                <li>C#</li>
                                <li>React</li>
                                <li>Java</li>
                                <li>Javascript</li>
                                <li>CSS</li>
                                <li>Python</li>
                                <li>DevOps</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div>
                    <section id="employment-history">
                        <h2>{t("employment-history")}</h2>
                        <article>
                            <h3><span>Piggy</span><span>{t('piggy-role')}</span></h3>
                            <em>{t('dates.october')} 2020 - {t('dates.present')}</em>
                            <div dangerouslySetInnerHTML={{__html: t('piggy-text')}}></div>
                        </article>
                        <article>
                            <h3><span>BINCKBANK</span><span>{t('binckbank-role')}</span></h3>
                            <em>{t('dates.november')} 2016 - {t('dates.october')} 2020</em>
                            <div dangerouslySetInnerHTML={{__html: t('binckbank-text')}}></div>
                        </article>
                        <article>
                            <h3><span>IRP</span><span>{t('irp-role')}</span></h3>
                            <em>{t('dates.august')} 2015 - {t('dates.februari')} 2016</em>
                            <div dangerouslySetInnerHTML={{__html: t('irp-text')}}></div>
                        </article>
                        <article>
                            <h3><span>Digital Sparrow</span><span>{t('digital-sparrow-role')}</span></h3>
                            <em>{t('dates.februari')} 2013 - {t('dates.april')} 2015</em>
                            <div dangerouslySetInnerHTML={{__html: t('digital-sparrow-text')}}></div>
                        </article>
                        <article>
                            <h3><span>CE Repair</span><span>{t('cerepair-role')}</span></h3>
                            <em>{t('dates.februari')} 2014 - {t('dates.july')} 2014</em>
                            <div dangerouslySetInnerHTML={{__html: t('cerepair-text')}}></div>
                        </article>
                    </section>
                    <section id="education">
                        <h2>{t('education')}</h2>
                        <article className="columns">
                            <div className="column-extra-small">
                                <div>2016</div>
                                <div>2010</div>
                            </div>
                            <div>
                                <h3>{t('bachelor-title')}</h3>
                                <em>{t('bachelor-subtitle')}</em>
                                <p>{t('bachelor-text')}</p>
                            </div>
                        </article>
                        <article className="columns">
                            <div className="column-extra-small">
                                <div>2008</div>
                                <div>2003</div>
                            </div>
                            <div>
                                <h3>{t('havo-title')}</h3>
                                <em>{t('havo-subtitle')}</em>
                                <p>{t('havo-text')}</p>
                            </div>
                        </article>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default withTranslation('common')(CV);
